<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="我的账单"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="mainWrap">
        <div class="query_box">
          <div class="query_box_item">
            <span class="query_box_item_tit">车牌号码</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openVhPopup">{{
                  selVhVal ? selVhVal : "选择车牌号码"
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
                <van-popup
                  v-model="showVhPopup"
                  @close="onVhClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="vhColumns"
                    @change="onVhChange"
                    @confirm="onVhConfirm"
                    @cancel="onVhCancel"
                    show-toolbar
                    title="选择车牌号码"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup>
              </div>
            </div>
          </div>

          <div class="query_box_item">
            <span class="query_box_item_tit">时间范围</span>
            <div class="query_box_item_right" @click="openDate">
              <div class="section">
                <span class="showCommon">
                  {{ currentDateStartFormat }}-{{ currentDateEndFormat }}</span
                >

                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="list_box">
          <van-tabs v-model="active" @change="onChange">
            <van-tab title="停车缴费">
              <van-pull-refresh
                v-model="refreshing"
                @refresh="onRefresh"
                success-text="刷新成功"
                :disabled="errorFlag"
              >
                <van-list
                  v-model="loading"
                  :finished="finished"
                  :immediate-check="false"
                  :finished-text="parkPayRecordList.length===0?'':'没有更多了'"
                  :error.sync="error"
                  error-text="请求失败，点击重新加载"
                  @load="onLoad"
                >
                  <div
                    class="group_recharge"
                    v-for="(item,index) in parkPayRecordList"
                    :key="item.id"
                  >
                    <div class="itemBox" @click="openDetail(item, 1)">
                      <div class="mainBox">
                        <div class="mainBoxLeft">
                          <img
                            src="../../../assets/img/paymentRecordleft@2x.png"
                            class="mainBoxLeftIcon"
                          />
                          <div class="mainBoxLeftCenter">
                            <span class="mainBoxLeftCenterNum">{{
                              item.plate_number
                            }}</span>
                            <span class="mainBoxLeftCenterType">停车缴费</span>
                            <span class="mainBoxLeftCenterDate">{{
                              formatDate(item.create_time)
                            }}</span>
                          </div>
                        </div>
                        <div class="mainBoxRight">
                          <span class="mainBoxRightMoney"
                            >-{{ item.gross_price / 100 }}元</span
                          >
                        </div>
                      </div>
                      <div
                        class="lineline"
                        v-if="
                          monthPayRecordList.length > 1 &&
                          index !== monthPayRecordList.length - 1
                        "
                      ></div>
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
              <!-- 空状态 -->
              <van-empty
                v-if="parkPayRecordList.length == 0"
                description="暂无匹配数据"
              />
              <van-empty
                v-if="parkPayRecordList.length !== 0 && isError"
                image="error"
                description="查询异常"
              />
            </van-tab>
            <van-tab title="月卡续费">
              <van-pull-refresh
                v-model="refreshingMonth"
                @refresh="onRefresh"
                success-text="刷新成功"
                :disabled="errorFlagMonth"
              >
                <van-list
                  v-model="loadingMonth"
                  :finished="finishedMonth"
                  :immediate-check="false"
                  :finished-text="monthPayRecordList.length===0?'':'没有更多了'"
                  :error.sync="errorMonth"
                  error-text="请求失败，点击重新加载"
                  @load="onLoad"
                >
                  <div
                    class="group_recharge"
                    v-for="(item, index) in monthPayRecordList"
                    :key="item.id"
                  >
                    <div class="itemBox" @click="openDetail(item, 2)">
                      <div class="mainBox">
                        <div class="mainBoxLeft">
                          <img
                            src="../../../assets/img/fapiaojilu2@2x.png"
                            class="mainBoxLeftIcon"
                          />
                          <div class="mainBoxLeftCenter">
                            <span class="mainBoxLeftCenterNum">{{
                              item.plate_number
                            }}</span>
                            <!-- <span class="mainBoxLeftCenterType">月卡续费</span> -->
                            <span decode="true" class="mainBoxLeftCenterDate">

                              {{formatDate(item.charged_time)}}
                            </span>
                            <!-- <span decode="true" class="mainBoxLeftCenterDate">

                              有效期截止时间：{{formatDate(item.effective_date_end)}}
                            </span> -->
                          </div>
                        </div>
                        <div class="mainBoxRight">
                          <span class="mainBoxRightMoney"
                            >{{ item.charged_fee / 100 }}元</span
                          >
                        </div>
                      </div>
                      <div
                        class="lineline"
                        v-if="
                          monthPayRecordList.length > 1 &&
                          index !== monthPayRecordList.length - 1
                        "
                      ></div>
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
              <!-- 空状态 -->
              <van-empty
                v-if="monthPayRecordList.length == 0"
                description="暂无匹配数据"
              />
              <van-empty
                v-if="monthPayRecordList.length !== 0 && isError"
                image="error"
                description="查询异常"
              />
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <!-- 时间选择器 -->
      <date-select
        :show-date="showDatePopup"
        @get-on-close="getOnCloseDate"
        @doneHaveSelDate="doneHaveSelDate"
      ></date-select>
      <!-- 分页 -->
      <!-- <Pagination
      :total="pageOption.total"
      :page_no="pageOption.page_no"
      :page_size="pageOption.page_size"
      @page-change="handlePageChange"
    ></Pagination> -->
    </div>
  </div>
</template>
<script>
import DateSelect from '../../../components/DateSelect.vue'
// import Pagination from '../../../components/Pagination.vue'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
import * as constant from '../../../api/constant'
import axios from 'axios'
import { Toast, Dialog } from 'vant'
import { mapGetters } from 'vuex'

// import moment from 'moment'
export default {
  // components: { DateSelect, Pagination },
  components: { DateSelect },
  data () {
    return {
      tokenInfoObj: {},
      active: 0,

      show: false,

      objectArray: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          date: '2022/12/12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        },
        {
          id: 1,
          money: '25',
          groupName: '银湖分组2',
          date: '2023-11-11 13:13:13',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        },
        {
          id: 2,
          money: '25',
          groupName: '银湖分组2',
          date: '2023-11-11 13:13',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        }
      ],
      objectArray2: [
        {
          id: 0,
          money: '22',
          groupName: '银湖缴费分组',
          date: '2022-12-12 12:12',
          src: '../../../assets/img/rightjt.png'
        },
        {
          id: 1,
          money: '25',
          groupName: '银湖缴费分组',
          date: '2023-11-11 13:13',
          src: '../../../assets/img/rightjt.png'
        },
        {
          id: 2,
          money: '25',
          groupName: '银湖缴费分组',
          date: '2023-11-11 13:13',
          src: '../../../assets/img/rightjt.png'
        }
      ],
      // vhColumns: ['全部', '浙A12345', '中国', '巴西', '日本'],
      index: 0,
      array2: ['今天', '明天', '前天', '巴西', '日本'],
      index2: 0,
      recentDates: '',
      lastAmonth: '',
      lastThreeMonth: '',
      plateNum: '',
      user_info: {},
      token: '',
      parkPayRecordList: [

      ],
      monthPayRecordList: [
        // {
        //   id: 0,
        //   money: '22',
        //   groupName: '银湖分组',
        //   date: '2022-12-12 12:12:12',
        //   src: '../../../assets/img/rightjt.png',
        //   plateNum: '浙A88899'
        // }
      ],
      isError: false,
      showVhPopup: false,
      selVhVal: '',
      showDatePopup: false,
      currentDateStartFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      currentDateEndFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      pageOption: {
        page_no: 1,
        page_size: 20,
        total: 0
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      errorFlag: false,

      loadingMonth: false,
      finishedMonth: false,
      refreshingMonth: false,
      errorMonth: false,
      errorFlagMonth: false,
      qryFlag: false,
      loadingQry: false
    }
  },
  mounted () {
    console.log('打开停车缴费还是月租相关', this.$route.query.type)
    const type = this.$route.query.type || 0
    const detailType = sessionStorage.getItem(constant.keyRenewBillDetailTypeSession)
    console.log('detailtype: ', detailType)
    this.active = detailType ? Number(detailType) : Number(type)
    this.onChange()
    if (!this.selVhVal || this.selVhVal === '') {
      this.selVhVal = this.vhColumns[0]
    }
    this.$nextTick(() => {
      if (!this.vhColumns || this.vhColumns.length === 0) {
        Dialog.confirm({
          title: '提示',
          message: '您还没有绑定车辆，是否前往绑定？',
          confirmButtonColor: '#4797EA'
        })
          .then(() => {
            // on confirm
            this.$router.push('/myCar')
          })
          .catch(() => {
            // on cancel
            // TODO 本地开发允许执行
            // console.log('打开停车缴费还是月租相关', this.$route.query.type)
            // const type = this.$route.query.type
            // const detailType = sessionStorage.getItem('billDetailType')
            // console.log('detailtype: ', detailType)
            // this.active = detailType ? Number(detailType) : Number(type)
            // this.onChange()
          })
      } else {
        // console.log('打开停车缴费还是月租相关', this.$route.query.type)
        // const type = this.$route.query.type
        // this.active = Number(type)
        // this.onChange()
      }
    })
  },
  computed: {
    ...mapGetters(['vhColumns', 'openID', 'sid'])
  },
  methods: {
    monthTypeFormatter (str) {
      const type = Number(str)
      const list = constant.chargeTypeList
      const item = list.find((x) => x.type === type)
      return item ? item.name : '停车缴费'
    },
    formattedDate (dateString) {
      // const localDate = moment.utc(dateString).local()
      // return localDate.format('YYYY/MM/DD HH:mm:ss')
      return dateString.replace(/-/g, '/') //
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyy/MM/dd hh:mm:ss')
    },

    doneHaveSelDate (date1, date2) {
      this.currentDateStartFormat = date1
      this.currentDateEndFormat = date2
      this.showDatePopup = false
      this.qryFlag = true
      // this.finishedMonth = true
      console.log('@@@@@', this.qryFlag)
      this.queryPayRecord()
    },
    getOnCloseDate () {
      this.showDatePopup = false
    },
    openVhPopup () {
      this.showVhPopup = true
    },
    onVhClose () {},
    onVhChange () {},
    onVhConfirm (value) {
      this.selVhVal = value
      this.showVhPopup = false
      this.qryFlag = true
      this.pageOption.page_no = 1
      this.queryPayRecord()
    },
    onVhCancel () {
      this.showVhPopup = false
    },
    openDate () {
      this.showDatePopup = true
    },
    queryTab () {
      console.log('init queryTab')
    },
    // 切换Tab
    onChange () {
      console.log('onChange', this.active)
      this.pageOption.page_no = 1
      this.qryFlag = true
      this.refreshingMonth = false
      this.queryPayRecord()
    },
    openDetail (item, type) {
      sessionStorage.setItem(constant.keyRenewBillDetailTypeSession, type === 1 ? 0 : 1)
      console.log('item', item)
      const encodedItem = encodeURIComponent(JSON.stringify(item))
      this.$router.push({
        name: 'myBillDetail',
        params: { type: type, item: encodedItem }
      })
      // this.$router.push({ path: '/myBillDetail', query: { type: type, data: item } })
    },
    async queryPayRecord () {
      try {
        const theUtl = this.active === 0 ? api.urlList.car_owner.bill.get_bill : api.urlList.car_owner.vh.get_rent_history
        const paraData = {
          // sid: 'yuen03',
          sid: this.sid,
          // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
          openid: this.openID,
          plate_number_list: this.selVhVal ? [this.selVhVal] : [],
          page_no: this.pageOption.page_no,
          page_size: this.pageOption.page_size,
          begin_time: this.currentDateStartFormat ? `${this.currentDateStartFormat} 00:00:00` : '',
          end_time: this.currentDateEndFormat ? `${this.currentDateEndFormat} 23:59:59` : '',
          version: 'v2'
        }
        if (this.active === 0) {
          paraData.type = 1 // 停车缴费1，充值延期2
        }

        const { data } = await axios({
          url: `${common.iotUrl}/uuid/${theUtl}`,
          method: 'POST',
          data: paraData,
          headers: {
            'content-type': 'application/json'
          }
        })

        if (Number(data.code) === 10000) {
          this.pageOption.total = data.total

          // const isPark = type === 'park'
          const isPark = this.active === 0
          console.log('@@@@ispark', isPark)
          const recordList = isPark ? this.parkPayRecordList : this.monthPayRecordList
          const finishedFlag = isPark ? 'finished' : 'finishedMonth'
          const loadingFlag = isPark ? 'loading' : 'loadingMonth'
          const refreshingFlag = isPark ? 'refreshing' : 'refreshingMonth'

          if (this[refreshingFlag] || this.qryFlag) {
            console.log('查询完毕1', this[refreshingFlag])

            this[isPark ? 'parkPayRecordList' : 'monthPayRecordList'] = data.list
            this.qryFlag = false
            this[loadingFlag] = false
            this[refreshingFlag] = false
            this[finishedFlag] = recordList.length >= data.total
          } else {
            if (data.list.length > 0) {
              this[isPark ? 'parkPayRecordList' : 'monthPayRecordList'] = [...recordList, ...data.list]
            }
            this[loadingFlag] = false
            this[finishedFlag] = recordList.length >= data.total
            console.log('查询完毕2')
          }
        } else {
          Toast.fail('查询出错', data.msg)
        }
      } catch (e) {
        console.error('Error fetching payment records:', e)
        this.$toast('查询异常', e)
      }
    },

    onClickLeft () {
      this.$router.go(-1)
    },

    onRefresh (type) {
      const isPark = type === 'park'
      this[isPark ? 'parkPayRecordList' : 'monthPayRecordList'] = []
      this[isPark ? 'finished' : 'finishedMonth'] = false
      this[isPark ? 'loading' : 'loadingMonth'] = true
      this.queryPayRecord(type)
    },

    onLoad (type) {
      // const isPark = type === 'park'
      const isPark = this.active === 0
      const recordList = isPark ? this.parkPayRecordList : this.monthPayRecordList
      const loadingFlag = isPark ? 'loading' : 'loadingMonth'
      const refreshingFlag = isPark ? 'refreshing' : 'refreshingMonth'
      const finishedFlag = isPark ? 'finished' : 'finishedMonth'
      const errorFlag = isPark ? 'errorFlag' : 'errorFlagMonth'

      if (this[refreshingFlag]) {
        this.pageOption.page_no = 1
        this.queryPayRecord(type)
      } else {
        if (this[errorFlag]) {
          this.pageOption.page_no = 1
          this[errorFlag] = false
          this.queryPayRecord(type)
        } else if (recordList.length >= this.pageOption.total) {
          this[finishedFlag] = true
        } else {
          this.pageOption.page_no += 1
          this.queryPayRecord(type)
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('账单返回了', to, from, next)
    // TODO
    // 调用方法清除 session 数据
    if (to.path === '/index') {
      sessionStorage.removeItem(constant.keyRenewBillDetailTypeSession)
    }
    // 继续路由导航
    next()
  }
}
</script>
<style lang="scss" scoped>
.page {
}
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  overflow: hidden;
  .rightIcon {
    width: 0.08rem;
    height: 0.14rem;
    margin: 0.03rem 0 0.03rem 0.05rem;
  }
  .lineline {
    height: 0.01rem;
    background-color: #e2e2e2;
    margin-top: 0.07rem;
    margin-bottom: 0.07rem;
  }
  .query_box {
    width: 3.43rem;
    // height: 5rem;
    display: flex;
    flex-direction: column;
    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      /* width: 686rpx; */
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .selPlateNum {
            font-size: 0.14rem;
          }
        }
      }
    }

    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .showCommon {
            font-size: 0.14rem;
          }
          .rightIcon {
            width: 0.08rem;
            height: 0.14rem;
            margin: 0.03rem 0 0.03rem 0.05rem;
          }
        }
        .image_4-1 {
          position: absolute;
          left: 0;
          top: -0.01rem;
          width: 3.42rem;
          height: 0.01rem;
          background-color: #f0f0f0;
        }
      }
    }
  }

  .list_box {
    background-color: rgba(255, 255, 255, 1);
    width: 3.43rem;
    align-self: center;
    margin-top: 0.16rem;
    display: flex;
    flex-direction: column;
    padding: 0.08rem 0.01rem 0.01rem 0;
    overflow-y: auto;
    .itemBox {
      width: 3.25rem;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      margin: 0.04rem 0.07rem 0.1rem 0.1rem;
      .mainBox {
        width: 3.25rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        /* margin: 8rpx 14rpx 0 20rpx; */
        .mainBoxLeft {
          /* width: 280rpx; */
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          .mainBoxLeftIcon {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0rem 0;
          }
          .mainBoxLeftCenter {
            display: flex;
            flex-direction: column;
            margin-left: 0.06rem;
            .mainBoxLeftCenterNum {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
              margin-right: 0.39rem;
            }
            .mainBoxLeftCenterType {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin: 0.04rem 0.55rem 0 0;
            }

            .mainBoxLeftCenterDate {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin-top: 0.02rem;
            }
          }
        }
        .mainBoxRight {
          margin-bottom: 0.19rem;
          display: flex;
          flex-direction: column;
          .mainBoxRightMoney {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 0.16rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            // margin-left: 0.17rem;
          }
        }
      }
    }
  }
}
</style>
